
.productos_seccion {
  background-color: #ffd165;
  width: 100%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -4rem;
}

.productos_seccion h1 {
  color: black;
  font-size: 2.5rem;
}

.productos_seccion h2 {
  font-size: 1.8rem;
  font-weight: lighter;
  margin-bottom: 3rem;
  padding: 0 3rem;
  text-align: center;
  line-height: 1.6;
}

.productos {
  width: 95%;
  height: auto;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin-left: 1rem;
  margin-right: 2rem;
}

.productos::-webkit-scrollbar {
  display: none;
}

.productos_seccion a {
  color: black;
  font-weight: bold;
}
.producto_container {
  width: 230px;
  background-color: white;
  box-shadow: 1px 2px 8px -3px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 1px 2px 8px -3px rgba(0, 0, 0, 1);
  -moz-box-shadow: 1px 2px 8px -3px rgba(0, 0, 0, 1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  padding: 0 3rem;
}

.producto_imagen {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.producto_imagen img {
  width: 200px;
  height: auto;
}

.producto_container h1 {
  font-size: 1.6rem;
  text-align: center;
}

.producto_container h2 {
  font-size: 1.6rem;
  font-weight: 600;
}

.producto_container .estrellas {
  width: 120px;
  margin-bottom: 2rem;
}

.btn_green {
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border-style: solid;
  border-width: 6px;
  background: linear-gradient(0deg,
      rgba(159, 207, 41, 1) 18%,
      rgba(114, 174, 33, 1) 100%);
  border-color: #8ec92c;
  box-shadow: 4px 4px 0px 2px rgba(100, 162, 43, 1);
  -webkit-box-shadow: 4px 4px 0px 2px rgba(100, 162, 43, 1);
  -moz-box-shadow: 4px 4px 0px 2px rgba(100, 162, 43, 1);
  font-weight: 700;
  color: white;
  font-size: 1.7rem;
  -webkit-appearance: none;
  margin: 2rem 0;
}