.preguntas {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  row-gap: 0.6rem;
  margin-bottom: 3rem;
  padding-bottom: 8rem;
}

.accordion {
  margin: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.accordion__item {
  width: 50%;
  max-height: 25px;
  padding: 2rem 1rem;
  border-bottom: 0.5px solid white;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
  margin-top: 2rem;
}

.accordion__item p {
  font-size: 1.8rem;
  line-height: 1.6;
}

.accordion__header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.accordion__header h4 {
  transition: 0.2s ease-in-out;
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.accordion__header i {
  transition: 0.2s ease-in-out;
  transform-origin: center;
  font-size: 1.6rem;
}

.accordion__header:hover h4 {
  color: #feda39 !important;
}

.accordion__header:hover i {
  color: #feda39;
}

.accordion__content {
  margin: 5px;
  font-size: 1.6rem;
}

@media (min-width: 900px) {
  .accordion__item {
    width: 40%;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  .accordion__item {
    width: 80%;
  }
}
