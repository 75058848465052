.info_seccion h1 {
  font-size: 2.2rem;
  padding: 0 3rem;
  color: white;
  text-align: center;
  line-height: 2;
}

.info_seccion {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

p {
  font-size: 2rem;
  color: white;
}

.mainPage {
  margin-top: 10rem;
  padding-top: 1rem;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.seleccion_fidelidad {
  width: 100vw;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.btn_salas,
.btn_virtual {
  width: 150px;
  background: linear-gradient(
    180deg,
    rgba(252, 192, 44, 1) 35%,
    rgba(247, 156, 38, 1) 100%
  );
  border-style: none;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 40px;
  color: black;
  font-size: 1.6rem;
  font-weight: 700;
}

.info {
  background: linear-gradient(
    180deg,
    rgba(252, 192, 44, 1) 35%,
    rgba(247, 156, 38, 1) 100%
  );
  padding: 1rem;
  width: 340px;
  border-radius: 15px;
  height: 240px;
}

.container {
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 0.1fr 0.3fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "titulonombre tituloedad"
    "nombre edad";
  background: linear-gradient(
    0deg,
    rgba(206, 59, 27, 1) 35%,
    rgba(247, 102, 55, 1) 100%
  );
  border-radius: 10px;
  height: 60px;
  padding: 1rem;
  padding-left: 1.5rem;
}

.titulonombre {
  grid-area: titulonombre;
}

.titulonombre,
.tituloedad {
  font-size: 1.6rem;
  color: white;
  font-weight: 600;
  margin-top: 0.5rem;
}

.tituloedad {
  grid-area: tituloedad;
}

.nombre {
  grid-area: nombre;
}

.edad {
  grid-area: edad;
}

.nombre,
.edad {
  font-size: 2.2rem;
  color: #ffd165;
  font-weight: 600;
  margin-top: 0.5rem;
  font-weight: bold;
}

.recompensas {
  display: flex;
  flex-direction: row;
}

.puntos,
.regalos {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 0.1fr 0.3fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "logofidelidad titulopuntos"
    "logofidelidad qypuntos";
  width: 140px;
  height: 70px;
  background: linear-gradient(
    0deg,
    rgb(176, 218, 50) 35%,
    rgba(114, 174, 33, 1) 100%
  );
  border-radius: 10px;
  margin-top: 2rem;
  margin-left: 3rem;
  align-items: center;
}

.titulopuntos {
  grid-area: titulopuntos;
}

.titulopuntos,
.tituloregalos {
  color: #436426;
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 12px;
}

.juegores_seccion{
background-color: #ffd165;
width: 100%;
padding: 4rem 0;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-top: -4rem;
}

.juegores_seccion h2 {
font-size: 1.8rem;
font-weight: lighter;
margin-bottom: 1rem;
padding: 0 3rem;
text-align: center;
line-height: 1.6;
text-align: center;
}
.juegores_seccion h1 {
color: black;
font-size: 2.5rem;
text-align: center;
margin-top: 2rem;
}

.logofidelidad,
.logoregalos {
  grid-area: logofidelidad;
}

.logofidelidad img {
  width: 80px;
  margin-left: -30px;
  margin-right: 0.5rem;
  margin-top: 13px;
}

.logoregalos img {
  width: 60px;
  margin-left: -20px;
  margin-right: 1.5rem;
  margin-top: 15px;
}

.qypuntos {
  grid-area: qypuntos;
}

.qypuntos,
.qyregalos {
  color: white;
  font-size: 2.6rem;
  font-weight: bold;
}

.botones_info {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin-top: -25px;
}

.btn_purple {
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border-style: solid;
  border-width: 6px;
  background: linear-gradient(
    180deg,
    rgba(172, 44, 252, 1) 35%,
    rgba(215, 83, 255, 1) 100%
  );
  border-color: #7c199a;
  box-shadow: 4px 4px 0px 2px rgba(82, 21, 92, 1);
  -webkit-box-shadow: 4px 4px 0px 2px rgba(82, 21, 92, 1);
  -moz-box-shadow: 4px 4px 0px 2px rgba(82, 21, 92, 1);
  font-weight: 700;
  color: white;
  font-size: 1.7rem;
  -webkit-appearance: none;
  margin-top: 4rem;
  margin-bottom: 11rem;
}
