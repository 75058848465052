.header_container {
  width: 100%;
  height: 6.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #3c0518;
  border-bottom: solid;
  border-width: 2.5rem;
  border-color: #bf4dce;
  position: fixed;
  top: 0;
  box-shadow: 0px -1px 12px -2px rgba(0, 0, 0, 0.71);
  -webkit-box-shadow: 0px -1px 12px -2px rgba(0, 0, 0, 0.71);
  -moz-box-shadow: 0px -1px 12px -2px rgba(0, 0, 0, 0.71);
}

.menuToggle {
  width: 15px;
  font-size: 3.5rem;
  color: white;
  padding-left: 1rem;
  cursor: pointer;
}

.header_logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header_logo img {
  width: 220px;
  padding-top: 5rem;
  cursor: pointer;
}

#toggle {
  position: relative;
  width: 50px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-radius: 5px;
}

hr {
  border-style: none;
}

hr::before {
  content: "";
  position: absolute;
  display: block;
  width: 28px;
  height: 2px;
  background: #fff;
  transition: 0.2s;
  transform: translateY(-8px);
  margin-left: -14px;
  box-shadow: 0 8px 0 #fff;
}

hr::after {
  content: "";
  position: absolute;
  display: block;
  width: 28px;
  height: 2px;
  background: #fff;
  transition: 0.2s;
  transform: translateY(8px);
  margin-left: -14px;
}



