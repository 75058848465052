.menu_nav {
  width: 100vw;
  background-color: #fafafa;
  position: absolute;
  top: 78px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

ul {
    list-style: none;
    display: flex;
    flex-direction: column;
}

li {
    margin: 20px;
}

.nav_item:hover {
    background-color: #AC194B;
    color: #fff;
    border-radius: 5px;
}

.nav_item {
    display: flex;
    width: 80vw;

}

.menu_nav a {
    all: unset;
    font-size: 1.8rem;
    padding: 1rem;
}

#sorteos-nav {
    scroll-margin-top: 8rem;
    
}

#productos-nav, #beneficios-nav, #preguntas-nav, #juegores-nav, #contacto-nav {
    scroll-margin-top: 6rem;
}
